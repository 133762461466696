const CONSTANTS = {
  type: {
    success: 'success',
    error: 'error',
    warning: 'warning',
    info: 'info',
    confirmation: 'confirmation',
  },

  notification: {
    message: null,
    type: null,
    autoDismiss: 5, // seconds
    dismissible: true,
    onClose: null,
  },
};

export default CONSTANTS;
