import * as ActionTypes from './types/NotificationActionTypes';
import CONSTANTS from '../components/common/notifications/constants';

export function show(opts = CONSTANTS.notification, type = CONSTANTS.type.success) {
  return {
    type: ActionTypes.SHOW_NOTIFICATION,
    payload: Object.assign({}, CONSTANTS.notification, opts, {
      id: new Date().valueOf(), // Might need to make this more random
      type,
    }),
  };
}

export function success(opts) {
  return show(opts, CONSTANTS.type.success);
}

export function error(opts) {
  return show(opts, CONSTANTS.type.error);
}

export function warning(opts) {
  return show(opts, CONSTANTS.type.warning);
}

export function info(opts) {
  return show(opts, CONSTANTS.type.info);
}

export function confirmation(opts) {
  return show({ ...opts, autoDismiss: 5 }, CONSTANTS.type.confirmation);
}

export function hide(id) {
  return {
    type: ActionTypes.HIDE_NOTIFICATION,
    payload: id,
  };
}

export function clear() {
  return { type: ActionTypes.CLEAR_NOTIFICATIONS };
}
