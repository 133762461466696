import * as ActionType from './types/ModalActionTypes';

export const hideModal = () => ({ type: ActionType.HIDE_MODAL });
export const showModal = (modalType, modalProps = {}, modalOptions = {}) => ({
  type: ActionType.SHOW_MODAL,
  payload: {
    modalType,
    modalProps,
    modalOptions,
  },
});
